<template>
    <div>
        <v-container class="paper-border">
            <v-row>
                <v-col cols="4" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.buyer') }}</span>
                    <span :class="Invoice__Customer__title ? '' : 'red--text'">{{ Invoice__Customer__title ? Invoice__Customer__title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.paymentTerms') }}</span>
                    <span :class="Invoice__Paymentstatement__title ? '' : 'red--text'">{{ Invoice__Paymentstatement__title ? Invoice__Paymentstatement__title : $t('message.valueMissing') }}</span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column py-0">
                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                    <span :class="Invoice__invdate ? '' : 'red--text'">{{ Invoice__invdate ? formatDate(Invoice__invdate) : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0 offset-4">
                    <span class="font-weight-bold">{{ $t('message.termsOfDelivery') }}</span>
                    <span :class="contractIncoterm ? '' : 'red--text'">
                        {{ contractIncoterm ? contractIncoterm : $t('message.valueMissing') }}
                    </span>
                </v-col>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0">
                    <span class="font-weight-bold">{{ $t('message.invoiceNumber') }}</span>
                    <span :class="Invoice__invno ? '' : 'red--text'">{{ Invoice__invno ? Invoice__invno : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0 offset-8">
                    <span class="font-weight-bold">{{ $t('message.contractNumber') }}</span>
                    <span :class="Invoice__Contract__title ? '' : 'red--text'">{{ Invoice__Contract__title ? Invoice__Contract__title : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pt-1 pb-0 offset-8">
                    <span class="font-weight-bold">{{ $t('message.marketing') }}</span>
                    <span :class="Invoice__Salescontact__name ? '' : 'red--text'">{{ Invoice__Salescontact__name ? Invoice__Salescontact__name : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-simple-table
                    class="pt-2"
                    dense
            >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.specification') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.size') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.grade') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black text-right width-10-pct grey lighten-4">{{ $t('message.quantity') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black text-right width-10-pct grey lighten-4">{{ $t('message.price') + (Invoice__Contract__currency_id != Invoice__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__Contract__currency_id).Currency.code+')' : '') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black text-right width-10-pct grey lighten-4">{{ $t('message.amt') + (Invoice__Contract__currency_id != Invoice__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__Contract__currency_id).Currency.code+')' : '') }}</th>
                            <th class="px-0 subtitle-1 font-weight-black text-right width-10-pct grey lighten-4" v-if="Invoice__Contract__currency_id != Invoice__currency_id">{{ $t('message.price') + '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ')' }}</th>
                            <th class="px-0 subtitle-1 font-weight-black text-right width-10-pct grey lighten-4" v-if="Invoice__Contract__currency_id != Invoice__currency_id">{{ $t('message.amt') + '(' + allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.code + ')' }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in Salesitems" :key="index">
                            <td :class="(createSpecification(item).trim().length == 0 ? 'red--text ' : '') + 'px-0 border-0'">{{ createSpecification(item).trim().length == 0 ? $t('message.valueMissing') : createSpecification(item) }}</td>
                            <td :class="['othercharge','credit'].indexOf(item.type) == -1 && item.Size.title == null ? 'red--text' : '' + 'px-0 border-0'" v-if="['othercharge','credit'].indexOf(item.type) == -1">{{ item.Size.title != null ? item.Size.title : $t('message.valueMissing') }}</td>
                            <td class="px-0 text-right border-0" v-else></td>
                            <td :class="['othercharge','credit'].indexOf(item.type) == -1 && item.Grade.title == null ? 'red--text' : '' + 'px-0 border-0'" v-if="['othercharge','credit'].indexOf(item.type) == -1">{{ item.Grade.title != null ? item.Grade.title : $t('message.valueMissing') }}</td>
                            <td class="px-0 text-right border-0" v-else></td>
                            <td class="px-0 text-right border-0" v-if="['othercharge','credit'].indexOf(item.type) == -1">{{ formatThisNumber(item.Salesitem.invqty, uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.formatPrecision) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title }}</td>
                            <td class="px-0 text-right border-0" v-else></td>
                            <td class="px-0 text-right border-0" v-if="['othercharge','credit'].indexOf(item.type) == -1">{{ formatThisNumber(item.Salesitem.invprice,'0,0.00') }}</td>
                            <td class="px-0 text-right border-0" v-else></td>
                            <td class="px-0 text-right border-0">{{ formatThisNumber(item.Salesitem.invoiceamount,'0,0.00') }}</td>
                            <td class="px-0 text-right border-0" v-if="Invoice__Contract__currency_id != Invoice__currency_id && ['othercharge','credit'].indexOf(item.type) == -1">{{ item.type != 'otherchargee' ? formatThisNumber(item.Salesitem.invprice_converted, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) : '' }}</td>
                            <td class="px-0 text-right border-0" v-if="Invoice__Contract__currency_id != Invoice__currency_id">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invoiceamount_converted, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) : '('+formatThisNumber(item.Salesitem.amount, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision)+')' }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="border-top-2"></td>
                            <td class="px-0 text-right font-weight-bold border-top-2">
                                <span v-for="(total,index) in itemsTotalQty">
                                        {{ formatThisNumber(total.Total.qty, uofmQty.find((uofm)=>uofm.Measurement.title == total.Total.uofm.trim()).Measurement.formatPrecision) + ' '+ total.Total.uofm.trim() }}
                                </span>
                            </td>
                            <td class="px-0 text-right font-weight-bold border-top-2">{{ $t('message.amountDue') }}</td>
                            <td class="px-0 text-right font-weight-bold border-top-2 text-no-wrap">{{ allCurrencies.find((currency) => currency.Currency.id == Invoice__Contract__currency_id).Currency.code + ' ' + formatThisNumber(itemsTotalAmount, allCurrencies.find((currency)=>currency.Currency.id == Invoice__Contract__currency_id).Currency.formatPrecision) }}</td>
                            <td class="border-top-2" v-if="Invoice__Contract__currency_id != Invoice__currency_id"></td>
                            <td class="px-0 text-right font-weight-bold border-top-2 text-no-wrap" v-if="Invoice__Contract__currency_id != Invoice__currency_id">{{ allCurrencies.find((currency) => currency.Currency.id == Invoice__currency_id).Currency.code + ' ' + formatThisNumber(itemsTotalAmountConverted, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</td>
                        </tr>
                        <tr v-if="office.Office.tax">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="px-0 text-right font-weight-bold">{{ office.Office.taxterm + ' ' + office.Office.taxrate + '%' }}</td>
                            <td class="px-0 text-right font-weight-bold">{{ allCurrencies.find((currency) => currency.Currency.id == Invoice__Contract__currency_id).Currency.code + ' ' + formatThisNumber(itemsTotalTax, allCurrencies.find((currency)=>currency.Currency.id == Invoice__Contract__currency_id).Currency.formatPrecision) }}</td>
                            <td v-if="Invoice__Contract__currency_id != Invoice__currency_id"></td>
                            <td class="px-0 text-right font-weight-bold" v-if="Invoice__Contract__currency_id != Invoice__currency_id">{{ allCurrencies.find((currency) => currency.Currency.id == Invoice__currency_id).Currency.code + ' ' + formatThisNumber(itemsTotalTaxConverted, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</td>
                        </tr>
                        <tr v-if="office.Office.tax">
                            <td class="border-bottom-2"></td>
                            <td class="border-bottom-2"></td>
                            <td class="border-bottom-2"></td>
                            <td class="border-bottom-2"></td>
                            <td class="px-0 text-right font-weight-bold border-bottom-2">{{ $t('message.grandTotal') }}</td>
                            <td class="px-0 text-right font-weight-bold border-bottom-2 text-no-wrap">{{ allCurrencies.find((currency) => currency.Currency.id == Invoice__Contract__currency_id).Currency.code + ' ' + formatThisNumber(itemsGrandTotal, allCurrencies.find((currency)=>currency.Currency.id == Invoice__Contract__currency_id).Currency.formatPrecision) }}</td>
                            <td class="border-bottom-2" v-if="Invoice__Contract__currency_id != Invoice__currency_id"></td>
                            <td class="px-0 text-right font-weight-bold border-bottom-2 text-no-wrap" v-if="Invoice__Contract__currency_id != Invoice__currency_id">{{ allCurrencies.find((currency) => currency.Currency.id == Invoice__currency_id).Currency.code + ' ' + formatThisNumber(itemsGrandTotalConverted, allCurrencies.find((currency)=>currency.Currency.id == Invoice__currency_id).Currency.formatPrecision) }}</td>
                        </tr>
                    </tfoot>
                </template>
            </v-simple-table>
            <v-row>
                <v-col cols="6" class="d-flex flex-column pb-0">
                    <span class="font-weight-bold">{{ $t('message.remarks') }}</span>
                    <span :class="Invoice__remark.trim().length > 0 ? '' : 'red--text'" style="white-space: pre; margin-left: -4px !important;">
                        {{ Invoice__remark.trim().length > 0 ? Invoice__remark.trim() : $t('message.valueMissing') }}
                    </span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column pb-0">
                    <span class="font-weight-bold"s>{{ $t('message.certification') }}</span>
                    <span :class="Invoice__Contract__fsc ? '' : 'red--text'">{{ certification }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="d-flex flex-column pb-0">
                    <span class="font-weight-bold">{{ $t('message.remitTo') }}</span>
                    <span :class="Invoice__Contract__bank_id ? '' : 'red--text'">
                        <span style="white-space: pre">{{ Invoice__Contract__bank_id ? contractBank : $t('message.valueMissing') }}</span>
                    </span>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, log, numberFormat} from "../../helpers/helpers";
    import {mapFieldsPrefixed,mapMultiRowFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'

    export default {
        name: "InvoicePreview",
        data(){
            return {}
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('invoice', {
                Invoice__Customer__title: 'Invoice.Customer.title',
                Invoice__Contract__bank_id: 'Invoice.Contract.bank_id',
                Invoice__Contract__currency_id: 'Invoice.Contract.currency_id',
                Invoice__Contract__fsc: 'Invoice.Contract.fsc',
                Invoice__Contract__incoterm_id: 'Invoice.Contract.incoterm_id',
                Invoice__Contract__incotermport: 'Invoice.Contract.incotermport',
                Invoice__Contract__loadingport_id: 'Invoice.Contract.loadingport_id',
                Invoice__Contract__office_id: 'Invoice.Contract.office_id',
                Invoice__Contract__salescontact_id: 'Invoice.Contract.salescontact_id',
                Invoice__Contract__shippingport_id: 'Invoice.Contract.shippingport_id',
                Invoice__Contract__title: 'Invoice.Contract.title',
                Invoice__currency_id: 'Invoice.currency_id',
                Invoice__gstpercent: 'Invoice.gstpercent',
                Invoice__invno: 'Invoice.invno',
                Invoice__invdate: 'Invoice.invdate',
                Invoice__remark: 'Invoice.remark',
                Invoice__Paymentstatement__title: 'Invoice.Paymentstatement.title',
                Invoice__Paymentterm__title: 'Invoice.Paymentterm.title',
                Invoice__Salescontact__name: 'Invoice.Salescontact.name'
            },'statePrefix'),
            ...mapGetters('bankaccount', {
                findBankAccountById: 'findBankAccountById'
            }),
            ...mapGetters('buyer',{
                findBuyerById: 'findBuyerById'
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries',
                findCountryById: 'findCountryById'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('paymentterm',{
                'findBuyerPaymentTermById': 'findBuyerPaymentTermById'
            }),
            ...mapGetters('salescolleague',{
                'findSalesColleagueById': 'findSalesColleagueById'
            }),
            ...mapMultiRowFieldsPrefixed('invoicesalesitem', {
                Salesitems: 'Salesitems',
            },'statePrefix'),
            ...mapFieldsPrefixed('invoicesalesitem', {
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalAmountConverted: 'itemsTotalAmountConverted',
                itemsTotalTax: 'itemsTotalTax',
                itemsTotalTaxConverted: 'itemsTotalTaxConverted',
                itemsGrandTotal: 'itemsGrandTotal',
                itemsGrandTotalConverted: 'itemsGrandTotalConverted',
                itemsTotalQty: 'itemsTotalQty',
                specificationNotes: 'specificationNotes'
            },'statePrefix'),
            ...mapGetters('shippingport', {
                findShippingPortById: 'findShippingPortById'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters([
                'buyerCaptionTypes',
                'certifications',
                'containerSizes',
                'currencies',
                'incoterms',
                'offices',
                'uofmQty'
            ]),
            buyerCaption() {
                return this.buyerCaptionTypes.find((caption)=>caption.id == this.Invoice__Contract__buyeraddresstype).title_first
            },
            certification() {
                let certification = this.certifications.find(certification => certification.Certification.id == this.Invoice__Contract__fsc)
                if( certification ){
                    return certification.Certification.title
                }
                return this.$t('message.valueMissing')
            },
            contractIncoterm() {
                let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Invoice__Contract__incoterm_id)?.Incoterm?.title;
                let incotermport = '';
                switch(this.Invoice__Contract__incotermport){
                    case 0: //POD
                        if(this.Invoice__Contract__shippingport_id != '' || this.Invoice__Contract__shippingport_id != null) {
                            incotermport = ' ' + this.findShippingPortById(this.Invoice__Contract__shippingport_id)?.Shippingport?.title
                        }
                        break;
                    case 1: //POL
                        if(this.Invoice__Contract__loadingport_id != '' || this.Invoice__Contract__loadingport_id != null) {
                            incotermport = ' ' + this.findShippingPortById(this.Invoice__Contract__loadingport_id)?.Shippingport?.title
                        }
                        break;
                    case 2:
                        return ''
                        break
                }
                return incoterm + incotermport;
            },
            contractBank(){
                let bank = '';
                if(this.Invoice__Contract__bank_id != null){
                    if([1].includes(this.Invoice__Contract__office_id)){
                        //APP Timber Sdn Bhd
                        let bankRM = this.findBankAccountById(153);
                        let bankUSD = this.findBankAccountById(168);
                        if(bankRM.Bank.title){ bank += this.$t('message.banker') + ': ' + bankRM.Bank.title.trim()}
                        if(bankRM.Bank.swiftcode){ bank += "\n" +  this.$t('message.swiftCode') + ': ' + bankRM.Bank.swiftcode.trim()}
                        if(bankRM.Bank.account){ bank += "\n" + this.$t('message.accountNo') + '(RM) : ' + bankRM.Bank.account.trim()}
                        if(bankUSD && bankUSD.Bank.account){ bank += "\n" + this.$t('message.accountNo') + '(USD) : ' + bankUSD.Bank.account.trim()}
                        if(bankRM.Bank.beneficiary){ bank += "\n" + this.$t('message.beneficiary') + ': ' + bankRM.Bank.beneficiary.trim()}
                    } else {
                        let contractBank = this.findBankAccountById(this.Invoice__Contract__bank_id)
                        if(contractBank.Bank.title){ bank += this.$t('message.banker') + ': ' + contractBank.Bank.title.trim()}
                        if(contractBank.Bank.swiftcode){ bank += "\n" +  this.$t('message.swiftCode') + ': ' + contractBank.Bank.swiftcode.trim()}
                        if(contractBank.Bank.account){ bank += "\n" + this.$t('message.accountNo') + ': ' + contractBank.Bank.account.trim()}
                        if(contractBank.Bank.beneficiary){ bank += "\n" + this.$t('message.beneficiary') + ': ' + contractBank.Bank.beneficiary.trim()}
                    }
                }
                return bank
            },
            deliveryCaption() {
                return this.buyerCaptionTypes.find((caption)=>caption.id == this.Invoice__Contract__buyeraddresstype).title_last
            },
            office() {
                return this.offices.find((office)=>office.Office.id == this.Invoice__Contract__office_id)
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_invoice')
            },
        },
        methods: {
            ...mapActions('country', {
                getAllCountries: 'getAllCountries'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('invoice',{
                saveInvoice: 'saveInvoice'
            }),
            createSpecification(item){
                let specification = '';
                if(item.type == 'othercharge'){
                    specification = item.Grade.title;
                } else if(item.type == 'credit'){
                    specification = item.Spec.title
                } else if(item.type == 'default'){
                    specification = (item.Species.title ? item.Species.title + ', ' : '') + (item.Salesitem.description ? item.Salesitem.description +', ' : item.Spec.title)
                }
                return specification
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
        },
        created(){
            if(this.allCurrencies.length == 0) this.getAllCurrencies();
            if(this.allCountries.length == 0) this.getAllCountries();
        }
    }
</script>

<style>
    .paper-border {
        border: dashed 1px darkgrey;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
</style>